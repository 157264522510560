import * as authRoutines from './features/auth/routines';
import * as seasonsRoutines from './features/seasons/routines';
import * as matchesRoutines from './features/matches/routines';
import * as teamsRoutines from './features/teams/routines';

export default {
  authRoutines,
  seasonsRoutines,
  matchesRoutines,
  teamsRoutines
};
