import axios from '../../../helpers/axios';
import { put, takeEvery, call } from 'redux-saga/effects';

import { getSeasons } from './routines';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchSeasons() {
  try {
    yield put(getSeasons.request());
    const getSeasonsResponse = yield call(axios().get, '/matches/seasons');

    yield put(getSeasons.success(getSeasonsResponse.data));
  } catch (error) {
    yield put(getSeasons.failure(error.response.data));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* requestWatcherSaga() {
  yield takeEvery(getSeasons.TRIGGER, fetchSeasons);
}
