import { Action } from 'redux';
import UIState from './interfaces/UIState';
import { AdminModeSuccessAction } from './interfaces/AdminModeActions';
import { setAdminMode } from './routines';

export const initialState: UIState = {
  adminMode: false
};

export default (state = initialState, action: Action): UIState => {
  switch (action.type) {
    case setAdminMode.SUCCESS:
      const adminMode = (action as AdminModeSuccessAction).payload;

      return {
        ...state,
        adminMode
      };
    default:
      return {
        ...state
      };
  }
};
