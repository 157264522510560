import dashboardRoutes from './dashboard/routes';
import loginRoutes from './login/routes';
import matchesRoutes from './matches/routes';
import forgotPasswordRoutes from './forgot-password/routes';
import resetPasswordRoutes from './reset-password/routes';
import importRoutes from './import/routes';
import error404Routes from './error-404/routes';

import RootPage from './index';

export type Route = {
  route?: string;
  component: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  layout?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  exact?: boolean;
  protected?: boolean;
  admin?: boolean;
};

export const routes: Route[] = [
  ...dashboardRoutes,
  ...loginRoutes,
  ...forgotPasswordRoutes,
  ...resetPasswordRoutes,
  ...matchesRoutes,
  ...importRoutes,
  {
    route: '/',
    component: RootPage,
    exact: true,
    protected: true
  },
  ...error404Routes
];

export default routes;
