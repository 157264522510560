import React from 'react';
import { FormInstance } from 'antd/lib/form';

import { Layout, Button, Card, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import styles from './ResetPassword.module.scss';
import logo from '../../assets/logo.png';

interface Props {
  processing: boolean;
  resetPassword: Function;
  form?: FormInstance;
}

const ResetPassword: React.FC<Props> = (props: Props) => {
  const handleSubmit = (values: { [key: string]: string }): void => {
    props.resetPassword(values.password);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Content>
        <img src={logo} alt="logo" className={styles.logo} />

        <div className={styles.formBox}>
          <Card>
            <p className={styles.title}>Reset password</p>

            <Form onFinish={handleSubmit} form={props.form}>
              <Form.Item name="password" rules={[{ required: true, message: ' ' }]} validateTrigger="onSubmit">
                <Input.Password
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item
                name="passwordRetype"
                rules={[
                  { required: true, message: ' ' },
                  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                  ({ getFieldValue }) => ({
                    validator(rule, value): Promise<void> {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match!');
                    }
                  })
                ]}
                validateTrigger="onSubmit"
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Retype Password"
                />
              </Form.Item>

              <div className={styles.actions}>
                <Button type="primary" htmlType="submit" loading={props.processing}>
                  Change
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default ResetPassword;
