import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Layout, Button, Card, Form, Input, notification } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';

import AppState from '../../store/interfaces/AppState';

import styles from './Login.module.scss';
import logo from '../../assets/logo.png';
import { StateError } from '../../store/interfaces/Error';

interface Props {
  processing: boolean;
  error: StateError | null;
  signIn: Function;
}

const Login: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    if (props.error) {
      notification.error({
        message: 'Log in error',
        description: props.error.code === 401 ? 'Incorrect E-mail or Password' : props.error.message
      });
    }
  }, [props.error]);

  const isAuthenticated = !!useSelector((state: AppState) => state.auth.token.token);
  if (isAuthenticated) {
    document.location.href = '/';
  }

  const handleSubmit = (values: { [key: string]: string }): void => {
    props.signIn(values);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Content>
        <div className={styles.loginBox}>
          <Card>
            <img src={logo} alt="logo" className={styles.logo} />

            <Form onFinish={handleSubmit}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: ' '
                  },
                  { required: true, message: ' ' }
                ]}
                validateTrigger="onSubmit"
              >
                <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="E-mail" />
              </Form.Item>

              <Form.Item name="password" rules={[{ required: true, message: ' ' }]} validateTrigger="onSubmit">
                <Input.Password
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <div className={styles.actions}>
                <Button type="primary" htmlType="submit" loading={props.processing}>
                  Log in
                </Button>

                <Link to="/forgot-password">Forgot password?</Link>
              </div>
            </Form>
          </Card>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Login;
