import axios from '../../../helpers/axios';
import { put, takeEvery, call } from 'redux-saga/effects';

import { SingInTriggerAction } from './interfaces/SingInActions';
import { signIn, signOut, getProfile, fetchToken } from './routines';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* login(action: SingInTriggerAction) {
  try {
    yield put(signIn.request());
    const createTokenResponse = yield call(axios().post, '/auth/token', action.payload);

    yield put(signIn.success(createTokenResponse.data));
    yield put(getProfile());
  } catch (error) {
    yield put(signIn.failure(error.response.data));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* logout() {
  yield put(signOut.success());
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* refreshToken() {
  try {
    yield put(fetchToken.request());
    const refreshTokenResponse = yield call(axios().post, '/auth/refresh-token');

    yield put(fetchToken.success(refreshTokenResponse.data));
  } catch (error) {
    yield put(fetchToken.failure(error.response.data));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchProfile() {
  try {
    yield put(getProfile.request());
    const profileResponse = yield call(axios().get, '/auth/me');

    yield put(getProfile.success(profileResponse.data));
  } catch (error) {
    yield put(getProfile.failure(error.response.data));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* requestWatcherSaga() {
  yield takeEvery(signIn.TRIGGER, login);
  yield takeEvery(signOut.TRIGGER, logout);
  yield takeEvery(fetchToken.TRIGGER, refreshToken);
  yield takeEvery(getProfile.TRIGGER, fetchProfile);
}
