import { Action } from 'redux';
import SeasonsState from './interfaces/SeasonsState';
import { SeasonsSuccessAction, SetCurrentSeasonSuccessAction } from './interfaces/SeasonsActions';
import { getSeasons as getSeasonsRoutine, setCurrentSeason as setCurrentSeasonRoutine } from './routines';
import { FailureAction } from '../../interfaces/Error';
import { transformError } from '../../../helpers/error';

export const initialState: SeasonsState = {
  list: [],
  processing: false,
  error: null
};

export default function reducer(state = initialState, action: Action): SeasonsState {
  switch (action.type) {
    case getSeasonsRoutine.REQUEST:
      return {
        ...state,
        processing: true,
        error: null
      };
    case getSeasonsRoutine.SUCCESS:
      const list = (action as SeasonsSuccessAction).payload;

      return {
        ...state,
        processing: false,
        list,
        current: list[0]
      };
    case getSeasonsRoutine.FAILURE:
      const { code, message } = (action as FailureAction).payload || {};
      const error = transformError(message, code);

      return {
        ...state,
        processing: false,
        error
      };
    case setCurrentSeasonRoutine.SUCCESS:
      const currentSeason = (action as SetCurrentSeasonSuccessAction).payload;

      return {
        ...state,
        list: state.list.some(item => item === currentSeason) ? [...state.list] : [currentSeason, ...state.list],
        current: currentSeason
      };
    default:
      return {
        ...state
      };
  }
}
