import { StateError } from '../store/interfaces/Error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformError = (message: string | any[], code: number): StateError => ({
  message: Array.isArray(message)
    ? message
        .map((item: { constraints: { [key: string]: string } }) => {
          if (!item.constraints) {
            return null;
          }

          return Object.values(item.constraints).join(', ');
        })
        .join(', ')
    : message || 'Something went wrong',
  code: code || 999
});
