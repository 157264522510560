import React, { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Button, Form, Row, Col, InputNumber } from 'antd';

import AppState from '../../../../../store/interfaces/AppState';
import { Match } from '../../../../../store/features/matches/interfaces/Match';

import styles from './BidForm.module.scss';

interface Props {
  processing: boolean;
  saveBid: Function;
  match?: Partial<Match>;
}

const BidForm: React.FC<Props> = (props: Props) => {
  const user = useSelector((state: AppState) => state.auth.profile.user);

  const bid = useMemo(() => {
    return (props.match?.bids || []).find(item => item.user?._id === user?._id);
  }, [props.match, user]);

  const handleSubmit = (values: { [key: string]: string }): void => {
    props.saveBid(props.match?._id, {
      _id: bid?._id,
      result: {
        home: values.homeResult,
        visitor: values.visitorResult
      }
    });
  };

  return (
    <Form
      onFinish={handleSubmit}
      initialValues={{
        homeResult: bid?.result?.home,
        visitorResult: bid?.result?.visitor
      }}
    >
      <Row>
        <Col span={12} className={styles.date}>
          {props.match?.date && moment(props.match?.date).format('YYYY-MM-DD')}
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <div className={styles.homeTeam}>
            <span>{props.match?.home?.team}</span>
          </div>
        </Col>

        <Col span={2}>
          <div className={styles.hypen}>-</div>
        </Col>

        <Col span={11}>
          <div className={styles.visitorTeam}>
            <span>{props.match?.visitor?.team}</span>
          </div>
        </Col>
      </Row>

      {props.match?._id && (
        <Row>
          <Col span={5} offset={6}>
            <Form.Item name="homeResult" rules={[{ required: true, message: ' ' }]}>
              <InputNumber precision={0} min={0} max={99} className={styles.width100} />
            </Form.Item>
          </Col>

          <Col span={5} offset={2}>
            <Form.Item name="visitorResult" rules={[{ required: true, message: ' ' }]}>
              <InputNumber precision={0} min={0} max={99} className={styles.width100} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <div className={styles.actions}>
        <Button type="primary" htmlType="submit" loading={props.processing}>
          {bid?._id ? 'Update' : 'Create'} Bid
        </Button>
      </div>
    </Form>
  );
};

export default BidForm;
