import React, { useEffect, useState } from 'react';
import axios from '../../helpers/axios';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { notification } from 'antd';

import AppState from '../../store/interfaces/AppState';
import { Match } from '../../store/features/matches/interfaces/Match';

import Dashboard from './Dashboard';

const DashboardContainer: React.FC = () => {
  const seasons = useSelector((state: AppState) => state.seasons.list);

  const [rounds, setRounds] = useState<{ [key: string]: Match[] }>({});

  const getMatches = async (season: string, startDate: string, endDate: string): Promise<void> => {
    try {
      const matches = await axios().get(`/matches?season=${season}&startDate=${startDate}&endDate=${endDate}`);

      const grouppedMatches = matches.data.reduce(
        (groupped: { [key: string]: Match[] }, current: Match) => ({
          ...groupped,
          [current.date]: [...(groupped[current.date] || []), current]
        }),
        {}
      );

      const newRounds: { [key: string]: Match[] } = {};

      for (const date in grouppedMatches) {
        if (grouppedMatches.hasOwnProperty(date)) {
          moment(date).isBefore(moment())
            ? (newRounds.last = grouppedMatches[date])
            : (newRounds.current = grouppedMatches[date]);
        }
      }

      setRounds(newRounds);
    } catch (error) {
      setRounds({});

      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }
  };

  useEffect(() => {
    seasons[0] &&
      getMatches(
        seasons[0],
        moment()
          .subtract(7, 'days')
          .toISOString(),
        moment()
          .add(7, 'days')
          .toISOString()
      );
  }, [seasons]);

  return <Dashboard rounds={rounds} />;
};

export default DashboardContainer;
