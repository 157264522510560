import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Layout, Menu, Button, Switch, Select, Popover, Form, Input } from 'antd';
import { PlusCircleOutlined, MenuOutlined } from '@ant-design/icons';

import { ProfileStateUser } from '../../../store/features/auth/interfaces/ProfileState';

import styles from './MainLayout.module.scss';
import logo from '../../../assets/logo2.png';

interface Props {
  children?: React.ReactNode;
  signOut: Function;
  changeAdminMode: Function;
  setCurrentSeason: Function;
  user: ProfileStateUser;
  adminMode: boolean;
  seasons: string[];
  currentSeason?: string;
  currentPath: string;
}

const MainLayout: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();
  const [isAddSeasonVisible, setIsAddSeasonVisible] = useState(false);

  return (
    <Layout className={styles.layout}>
      <Layout.Header className={styles.header}>
        <Link to="/">
          <img src={logo} alt="logo" className={styles.logo} />
        </Link>

        <div className={styles.topMenu}>
          <Menu
            theme="light"
            mode="horizontal"
            selectedKeys={[props.currentPath]}
            overflowedIndicator={<MenuOutlined />}
          >
            <Menu.Item key="/dashboard">
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>

            <Menu.Item key="/matches">
              <Link to="/matches">Matches</Link>
            </Menu.Item>
          </Menu>
        </div>

        <div className={styles.seasons}>
          <span>Season:</span>
          <Select
            id="seasonSelector"
            value={props.currentSeason}
            onChange={(season): void => props.setCurrentSeason(season)}
          >
            {props.seasons.map(season => (
              <Select.Option key={season} value={season}>
                {season}
              </Select.Option>
            ))}
          </Select>

          {props.adminMode && (
            <Popover
              title="Add season"
              trigger="click"
              visible={isAddSeasonVisible}
              onVisibleChange={setIsAddSeasonVisible}
              content={
                <Form
                  form={form}
                  onFinish={({ season }): void => {
                    props.setCurrentSeason(season);
                    setIsAddSeasonVisible(false);
                    form.resetFields();
                  }}
                >
                  <div className={styles.addSeasonForm}>
                    <Form.Item name="season" rules={[{ required: true, message: ' ' }]}>
                      <Input size="small" placeholder="season" />
                    </Form.Item>

                    <Button id="addSeason" type="primary" htmlType="submit" size="small">
                      Add
                    </Button>
                  </div>
                </Form>
              }
            >
              <Button id="createSeasonTrigger" type="link" shape="circle" icon={<PlusCircleOutlined />} size="small" />
            </Popover>
          )}
        </div>

        {props.user.isAdmin && (
          <div className={styles.adminMode}>
            <Switch
              checkedChildren="Admin Mode"
              unCheckedChildren="Admin Mode"
              checked={props.adminMode}
              onChange={(isChecked): void => props.changeAdminMode(isChecked)}
            />
          </div>
        )}

        <span className={styles.email}>{props.user.email}</span>

        <Button ghost type="primary" onClick={(): void => props.signOut()} className="logoutButton">
          Logout
        </Button>
      </Layout.Header>

      <Layout.Content className={styles.content}>{props.children}</Layout.Content>

      <Layout.Footer className={styles.footer}>Bw6 Hattrick &copy;{new Date().getFullYear()}</Layout.Footer>
    </Layout>
  );
};

export default MainLayout;
