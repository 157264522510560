import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import axios from '../../helpers/axios';

import { notification } from 'antd';

import Import from './Import';
import { Team } from '../../store/features/teams/interfaces/Team';
import AppState from '../../store/interfaces/AppState';

const ImportContainer: React.FC = () => {
  const currentSeason = useSelector((state: AppState) => state.seasons.current);

  const [importing, setImporting] = useState<boolean>(false);
  const [teams, setTeams] = useState<Team[]>([]);
  const [savingTeams, setSavingTeams] = useState<number[]>([]);
  const [matches, setMatches] = useState<
    {
      date: string;
      homeTeam: string;
      visitorTeam: string;
    }[]
  >([]);
  const [savingMatches, setSavingMatches] = useState<
    {
      date: string;
      homeTeam: string;
      visitorTeam: string;
    }[]
  >([]);

  const importTeams = async (url: string): Promise<void> => {
    setImporting(true);

    try {
      const teamsResult = await axios().get(`/parser/teams?url=${encodeURIComponent(url)}`);

      setTeams(teamsResult.data);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }

    setImporting(false);
  };

  const importMatches = async (url: string): Promise<void> => {
    setImporting(true);

    try {
      const matchesResult = await axios().get(`/parser/matches?url=${encodeURIComponent(url)}`);

      setMatches(matchesResult.data);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }

    setImporting(false);
  };

  const saveTeam = async (team: Team): Promise<void> => {
    setSavingTeams(prevSavingTeams => [...prevSavingTeams, team.hattrickId]);

    try {
      await axios().post(`/teams`, team);

      setTeams(oldTeams => oldTeams.filter(item => item.hattrickId !== team.hattrickId));
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }

    setSavingTeams(prevSavingTeams => prevSavingTeams.filter(id => id !== team.hattrickId));
  };

  const saveMatch = async (match: { date: string; homeTeam: string; visitorTeam: string }): Promise<void> => {
    setSavingMatches(prevSavingMatches => [...prevSavingMatches, match]);

    try {
      await axios().post(`/matches`, {
        season: currentSeason,
        date: `${match.date}T00:00:00`,
        home: {
          team: match.homeTeam
        },
        visitor: {
          team: match.visitorTeam
        }
      });

      setMatches(oldMatches => oldMatches.filter(item => !isEqual(item, match)));
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }

    setSavingMatches(prevSavingMatches => prevSavingMatches.filter(item => !isEqual(item, match)));
  };

  return (
    <Import
      importTeams={importTeams}
      saveTeam={saveTeam}
      importing={importing}
      teams={teams}
      savingTeams={savingTeams}
      importMatches={importMatches}
      saveMatch={saveMatch}
      matches={matches}
      savingMatches={savingMatches}
    />
  );
};

export default ImportContainer;
