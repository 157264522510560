interface Result {
  home: number;
  visitor: number;
}

export const bidColor = (match: Result, bid: Result): string => {
  return bid.home === match.home && bid.visitor === match.visitor
    ? match.home === match.visitor
      ? 'gold'
      : 'purple'
    : match.home === match.visitor && bid.home === bid.visitor
    ? 'blue'
    : (match.home < match.visitor && bid.home < bid.visitor) || (match.home > match.visitor && bid.home > bid.visitor)
    ? 'green'
    : 'crimson';
};
