import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';

import AppState from './store/interfaces/AppState';
import routes from './pages/routes';

import MainLayout from './components/Layout/MainLayout';

import './App.css';

const App: React.FC = () => {
  const isAuthenticated = !!useSelector((state: AppState) => state.auth.token.token);
  const loggedUser = useSelector((state: AppState) => state.auth.profile.user);

  return (
    <BrowserRouter>
      <MainLayout>
        <Switch>
          {routes.map((route, index) => {
            if (route.protected) {
              if (!isAuthenticated) {
                return <Redirect key={route.route} from={route.route} to="/login" />;
              }

              if (route.admin && loggedUser && !loggedUser.isAdmin) {
                return <Redirect key={route.route} from={route.route} to="/dashboard" />;
              }
            }

            return (
              <Route
                key={`${route.route}-${index}`}
                path={route.route}
                exact={route.exact || false}
                component={route.component}
              />
            );
          })}
        </Switch>
      </MainLayout>
    </BrowserRouter>
  );
};

export default App;
