import React from 'react';
import { Link } from 'react-router-dom';

import { Layout, Card } from 'antd';

import styles from './Error404.module.scss';
import logo from '../../assets/logo.png';

const ForgotPassword: React.FC = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Content>
        <Link to="/">
          <img src={logo} alt="logo" className={styles.logo} />
        </Link>

        <div className={styles.formBox}>
          <Card>
            <p className={styles.title}>Error 404</p>

            <p>Page not found</p>
          </Card>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default ForgotPassword;
