import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import routines from './store/routines';

import App from './App';

const token = localStorage.getItem('token');
if (token) {
  store.dispatch(routines.authRoutines.signIn.success({ token }));
  store.dispatch(routines.authRoutines.fetchToken());
  store.dispatch(routines.authRoutines.getProfile());
  store.dispatch(routines.teamsRoutines.getTeams());
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
