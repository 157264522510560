import _ from 'lodash';
import { Action } from 'redux';
import MatchesState, { LeaderboardItem } from './interfaces/MatchesState';
import { MatchesSuccessAction } from './interfaces/MatchesActions';
import { getMatches as getMatchesRoutine } from './routines';
import { FailureAction } from '../../interfaces/Error';
import { transformError } from '../../../helpers/error';

export const initialState: MatchesState = {
  list: [],
  processing: false,
  error: null
};

export default function reducer(state = initialState, action: Action): MatchesState {
  switch (action.type) {
    case getMatchesRoutine.REQUEST:
      return {
        ...state,
        processing: true,
        error: null
      };
    case getMatchesRoutine.SUCCESS:
      const list = (action as MatchesSuccessAction).payload;
      const leaderboard: {
        [dateOrTotal: string]: LeaderboardItem[];
      } = { total: [] };

      for (const match of list) {
        if (match.home.result === undefined || match.visitor.result === undefined) {
          continue;
        }

        if (!leaderboard[match.date]) {
          leaderboard[match.date] = [];
        }

        for (const bid of match.bids) {
          if (!bid.result) {
            continue;
          }

          const currentUserDataInDate = leaderboard[match.date].find(item => item.user._id === bid.user._id);
          const currentUserDataInTotal = leaderboard.total.find(item => item.user._id === bid.user._id);

          let points = 0;
          let smallPoints = 0;

          if (match.home.result === match.visitor.result && bid.result?.home === bid.result?.visitor) {
            points = match.home.result === bid.result.home ? 5 : 3;
            smallPoints =
              10 -
              Math.abs((match.home.result || 0) - bid.result.home) -
              Math.abs((match.visitor.result || 0) - bid.result.visitor);
          } else if (
            ((match.home.result || 0) > (match.visitor.result || 0) && bid.result.home > bid.result.visitor) ||
            ((match.home.result || 0) < (match.visitor.result || 0) && bid.result.home < bid.result.visitor)
          ) {
            points = match.home.result === bid.result.home && match.visitor.result === bid.result.visitor ? 3 : 1;
            smallPoints =
              10 -
              Math.abs((match.home.result || 0) - bid.result.home) -
              Math.abs((match.visitor.result || 0) - bid.result.visitor);
          }

          leaderboard[match.date] = _.orderBy(
            [
              ...leaderboard[match.date].filter(item => item.user._id !== bid.user._id),
              {
                user: bid.user,
                points: (currentUserDataInDate?.points || 0) + points,
                smallPoints: (currentUserDataInDate?.smallPoints || 0) + smallPoints
              }
            ],
            ['points', 'smallPoints'],
            ['desc', 'desc']
          );

          leaderboard.total = _.orderBy(
            [
              ...leaderboard.total.filter(item => item.user._id !== bid.user._id),
              {
                user: bid.user,
                points: (currentUserDataInTotal?.points || 0) + points,
                smallPoints: (currentUserDataInTotal?.smallPoints || 0) + smallPoints
              }
            ],
            ['points', 'smallPoints'],
            ['desc', 'desc']
          );
        }
      }

      return {
        ...state,
        processing: false,
        list,
        leaderboard
      };
    case getMatchesRoutine.FAILURE:
      const { code, message } = (action as FailureAction).payload || {};
      const error = transformError(message, code);

      return {
        ...state,
        processing: false,
        error
      };
    default:
      return {
        ...state
      };
  }
}
