import React, { Fragment, useState, useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Typography, Button, Modal, Row, Col, Card, Popconfirm } from 'antd';
import { EditOutlined, PlusOutlined, SwapOutlined, DeleteOutlined } from '@ant-design/icons';

import { bidColor } from '../../helpers/color';

import { MatchForm, BidForm } from './components';

import AppState from '../../store/interfaces/AppState';
import { Match } from '../../store/features/matches/interfaces/Match';
import { Team } from '../../store/features/teams/interfaces/Team';

import styles from './Matches.module.scss';

interface Props {
  processing: boolean;
  saveMatch: Function;
  removeMatch: Function;
  saveBid: Function;
  matches: Array<Match>;
  adminMode: boolean;
  isLastSeason: boolean;
  teams: { [key: string]: Team };
}

const Matches: React.FC<Props> = (props: Props) => {
  const user = useSelector((state: AppState) => state.auth.profile.user);

  const [editedItem, setEditedItem] = useState<Partial<Match>>();
  const [editedBidItem, setEditedBidItem] = useState<Partial<Match>>();

  const groupedMatches = useMemo(
    () =>
      props.matches.reduce(
        (acc: { [key: string]: Match[] }, item: Match) => ({
          ...acc,
          [item.date]: [...(acc[item.date] || []), item]
        }),
        {}
      ),
    [props.matches]
  );

  return (
    <Fragment>
      <Typography.Title>
        Matches
        {props.adminMode && (
          <Button type="primary" onClick={(): void => setEditedItem({})} disabled={!props.isLastSeason}>
            Create New Match
          </Button>
        )}
      </Typography.Title>

      {Object.entries(groupedMatches)
        .sort((a, b) => (a[0] < b[0] ? 1 : -1))
        .map(entry => {
          const [date, matches] = entry;

          if (moment(date).isAfter(moment().add(7, 'days'))) {
            return <React.Fragment />;
          }

          return (
            <div key={date} className={styles.round}>
              <Typography.Title level={3}>{moment(date).format('YYYY-MM-DD')}</Typography.Title>

              <Row gutter={20}>
                {matches.map(match => {
                  const actions = [];
                  props.adminMode &&
                    match.bids.length === 0 &&
                    actions.push(
                      <Popconfirm
                        title="Are you sure you want to delete the match?"
                        onConfirm={(): void => props.removeMatch(match._id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined key="delete" />
                      </Popconfirm>
                    );
                  props.adminMode &&
                    actions.push(<EditOutlined key="edit" onClick={(): void => setEditedItem(match)} />);
                  match.home.user !== user?._id &&
                    match.visitor.user !== user?._id &&
                    moment().format('YYYY-MM-DD') < moment(match.date).format('YYYY-MM-DD') &&
                    actions.push(<SwapOutlined key="bid" onClick={(): void => setEditedBidItem(match)} />);

                  return (
                    <Col key={match._id} xs={24} md={12}>
                      <Card className={styles.match} actions={actions}>
                        <div className={styles.teams}>
                          {props.teams[match.home.team] ? (
                            <Fragment>
                              <a
                                href={`https://www.hattrick.org/pl/Club/?TeamID=${
                                  props.teams[match.home.team].hattrickId
                                }`}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                className={styles.name}
                              >
                                {match.home.team}
                              </a>
                              <img src={props.teams[match.home.team].logo} alt="logo" />
                            </Fragment>
                          ) : (
                            <span className={styles.name}>{match.home.team}</span>
                          )}
                          <span className={styles.spacer}>-</span>
                          {props.teams[match.visitor.team] ? (
                            <Fragment>
                              <img src={props.teams[match.visitor.team].logo} alt="logo" />
                              <a
                                href={`https://www.hattrick.org/pl/Club/?TeamID=${
                                  props.teams[match.visitor.team].hattrickId
                                }`}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                className={styles.name}
                              >
                                {match.visitor.team}
                              </a>
                            </Fragment>
                          ) : (
                            <span className={styles.name}>{match.visitor.team}</span>
                          )}
                        </div>

                        {(props.adminMode || match.home.result !== undefined || match.visitor.result !== undefined) && (
                          <div className={styles.results}>
                            <span>
                              <span className={styles.result}>{match.home.result}</span>
                            </span>
                            <span className={styles.spacer} />
                            <span>
                              <span className={styles.result}>{match.visitor.result}</span>
                            </span>
                          </div>
                        )}

                        <div className={styles.bids}>
                          {match.bids.map(item => {
                            const bidStyles =
                              typeof match.home.result === 'number' && typeof match.visitor.result === 'number'
                                ? {
                                    color: 'white',
                                    backgroundColor: bidColor(
                                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                      { home: match.home.result!, visitor: match.visitor.result! },
                                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                      { home: item.result?.home!, visitor: item.result?.visitor! }
                                    )
                                  }
                                : {};

                            return (
                              <div key={item._id} className={styles.bid} style={bidStyles}>
                                <span>{item.user?.teamName}</span>
                                <span>{item.result ? `${item.result?.home} - ${item.result?.visitor}` : '? - ?'}</span>
                                <span />
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    </Col>
                  );
                })}

                {props.adminMode && matches.length < 5 && props.isLastSeason && (
                  <Col sm={24} md={12}>
                    <Card className={styles.addMatchCard}>
                      <Button
                        type="primary"
                        onClick={(): void => setEditedItem({ date })}
                        shape="circle"
                        icon={<PlusOutlined />}
                      />
                    </Card>
                  </Col>
                )}
              </Row>
            </div>
          );
        })}

      <Modal
        title="Match"
        visible={!!editedItem}
        destroyOnClose={true}
        onCancel={(): void => setEditedItem(undefined)}
        footer={null}
        maskClosable={false}
        keyboard={false}
      >
        <MatchForm saveMatch={props.saveMatch} match={editedItem} processing={props.processing} />
      </Modal>

      <Modal
        title="Bid"
        visible={!!editedBidItem}
        destroyOnClose={true}
        onCancel={(): void => setEditedBidItem(undefined)}
        footer={null}
        maskClosable={false}
        keyboard={false}
      >
        <BidForm saveBid={props.saveBid} match={editedBidItem} processing={props.processing} />
      </Modal>
    </Fragment>
  );
};

export default Matches;
