import { Action } from 'redux';
import TeamsState from './interfaces/TeamsState';
import { TeamsSuccessAction } from './interfaces/TeamsActions';
import { getTeams as getTeamsRoutine } from './routines';
import { FailureAction } from '../../interfaces/Error';
import { transformError } from '../../../helpers/error';

export const initialState: TeamsState = {
  list: [],
  processing: false,
  error: null
};

export default function reducer(state = initialState, action: Action): TeamsState {
  switch (action.type) {
    case getTeamsRoutine.REQUEST:
      return {
        ...state,
        processing: true,
        error: null
      };
    case getTeamsRoutine.SUCCESS:
      const list = (action as TeamsSuccessAction).payload;

      return {
        ...state,
        processing: false,
        list
      };
    case getTeamsRoutine.FAILURE:
      const { code, message } = (action as FailureAction).payload || {};
      const error = transformError(message, code);

      return {
        ...state,
        processing: false,
        error
      };
    default:
      return {
        ...state
      };
  }
}
