import axios from '../../../helpers/axios';
import { put, takeEvery, call, select } from 'redux-saga/effects';

import { getMatches } from './routines';
import AppState from '../../interfaces/AppState';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchMatches() {
  const currentSeason = yield select((state: AppState) => state.seasons.current);

  try {
    if (!!currentSeason) {
      yield put(getMatches.request());

      const getMatchesResponse = yield call(axios().get, `/matches?season=${currentSeason}`);

      yield put(getMatches.success(getMatchesResponse.data));
    } else {
      yield put(getMatches.success([]));
    }
  } catch (error) {
    yield put(getMatches.failure(error.response.data));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* requestWatcherSaga() {
  yield takeEvery(getMatches.TRIGGER, fetchMatches);
}
