import React, { useState } from 'react';
import axios from '../../helpers/axios';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button, Form, notification } from 'antd';

import ResetPassword from './ResetPassword';

interface MatchParams {
  hash: string;
}

type Props = RouteComponentProps<MatchParams>;

const ResetPasswordContainer: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();

  const [processing, setProcessing] = useState<boolean>(false);

  const goToLogin = (): void => {
    props.history.push('/login');
  };

  const resetPassword = async (password: string): Promise<void> => {
    setProcessing(true);

    try {
      await axios().patch('/forgot-password', {
        hash: props.match.params.hash,
        password
      });

      const key = `successNotification${Date.now()}`;

      notification.success({
        message: 'Success',
        description: 'Password has been changed',
        duration: 0,
        key,
        btn: (
          <Button
            type="primary"
            size="small"
            onClick={(): void => {
              goToLogin();
              notification.close(key);
            }}
          >
            Go to Login Page
          </Button>
        )
      });

      form.resetFields();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }

    setProcessing(false);
  };

  return <ResetPassword resetPassword={resetPassword} processing={processing} form={form} />;
};

export default withRouter(ResetPasswordContainer);
