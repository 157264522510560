import React from 'react';
import { Link } from 'react-router-dom';

import { FormInstance } from 'antd/lib/form';
import { Layout, Button, Card, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import styles from './ForgotPassword.module.scss';
import logo from '../../assets/logo.png';

interface Props {
  processing: boolean;
  sendEmail: Function;
  form?: FormInstance;
}

const ForgotPassword: React.FC<Props> = (props: Props) => {
  const handleSubmit = (values: { [key: string]: string }): void => {
    props.sendEmail(values.email);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Content>
        <img src={logo} alt="logo" className={styles.logo} />

        <div className={styles.formBox}>
          <Card>
            <p className={styles.title}>Forgot password</p>

            <Form onFinish={handleSubmit}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: ' '
                  },
                  { required: true, message: ' ' }
                ]}
              >
                <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="E-mail" />
              </Form.Item>

              <div className={styles.actions}>
                <Button type="primary" htmlType="submit" loading={props.processing}>
                  Send
                </Button>

                <Link to="/login">&laquo; back to Login</Link>
              </div>
            </Form>
          </Card>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default ForgotPassword;
