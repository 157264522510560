import { Action } from 'redux';
import TokenState from '../interfaces/TokenState';
import { SingInSuccessAction } from '../interfaces/SingInActions';
import { signIn as signInRoutine, fetchToken as fetchTokenRoutine } from '../routines';
import { FailureAction } from '../../../interfaces/Error';
import { transformError } from '../../../../helpers/error';

export const initialState: TokenState = {
  token: null,
  processing: false,
  error: null
};

export default function reducer(state = initialState, action: Action): TokenState {
  switch (action.type) {
    case signInRoutine.REQUEST:
      return {
        ...state,
        processing: true,
        error: null
      };
    case signInRoutine.SUCCESS:
      const token = (action as SingInSuccessAction).payload.token;
      localStorage.setItem('token', token);

      return {
        ...state,
        processing: false,
        token
      };
    case signInRoutine.FAILURE:
      const { code, message } = (action as FailureAction).payload || {};
      const error = transformError(message, code);

      return {
        ...state,
        processing: false,
        error
      };
    case fetchTokenRoutine.SUCCESS:
      const newToken = (action as SingInSuccessAction).payload.token;
      localStorage.setItem('token', newToken);

      return {
        ...state,
        processing: false,
        token: newToken
      };
    default:
      return {
        ...state
      };
  }
}
