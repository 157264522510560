import { all } from '@redux-saga/core/effects';

import auth from './features/auth/saga';
import seasons from './features/seasons/saga';
import matches from './features/matches/saga';
import teams from './features/teams/saga';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* rootSaga() {
  yield all([auth(), seasons(), matches(), teams()]);
}
