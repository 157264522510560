import MatchesPage from './index';
import MainLayout from '../../components/Layout';

export default [
  {
    route: '/matches',
    component: MatchesPage,
    protected: true,
    layout: MainLayout
  }
];
