import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';

import AppState from '../../../store/interfaces/AppState';
import { signOut } from '../../../store/features/auth/routines';
import { setAdminMode } from '../../../store/features/ui/routines';
import { getSeasons, setCurrentSeason } from '../../../store/features/seasons/routines';
import { getMatches } from '../../../store/features/matches/routines';

import MainLayout from './MainLayout';
import { useLocation } from 'react-router';

interface Props {
  children?: React.ReactNode;
}

const Container: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const user = useSelector((state: AppState) => state.auth.profile.user);
  const adminMode = useSelector((state: AppState) => state.ui.adminMode);
  const seasons = useSelector((state: AppState) => state.seasons.list);
  const currentSeason = useSelector((state: AppState) => state.seasons.current);

  useEffect(() => {
    !!user && dispatch(getSeasons());
  }, [dispatch, user]);

  useEffect(() => {
    !!currentSeason && dispatch(getMatches());
  }, [currentSeason, dispatch]);

  if (!user) {
    return <Fragment>{props.children}</Fragment>;
  }

  return (
    <MainLayout
      signOut={(): Action => dispatch(signOut())}
      changeAdminMode={(isChecked: boolean): Action => dispatch(setAdminMode.success(isChecked))}
      setCurrentSeason={(season: string): Action => dispatch(setCurrentSeason.success(season))}
      user={user}
      adminMode={adminMode}
      seasons={seasons}
      currentSeason={currentSeason}
      currentPath={location.pathname}
    >
      {props.children}
    </MainLayout>
  );
};

export default Container;
