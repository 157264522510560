import React, { Fragment } from 'react';

import { Form, Input, Typography, Button, Card } from 'antd';

import styles from './Import.module.scss';

import { Team } from '../../store/features/teams/interfaces/Team';

enum ImportTypes {
  Teams = 'teams',
  Matches = 'matches',
  Unrecognized = 'unrecognized'
}

interface Props {
  importTeams: Function;
  saveTeam: Function;
  importing: boolean;
  teams: Team[];
  savingTeams: number[];
  importMatches: Function;
  saveMatch: Function;
  matches: { date: string; homeTeam: string; visitorTeam: string }[];
  savingMatches: { date: string; homeTeam: string; visitorTeam: string }[];
}

const Import: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();

  const getImportType = (url: string): ImportTypes => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;

    if (pathname === '/pl/World/Series/') {
      return ImportTypes.Teams;
    }

    if (pathname === '/pl/World/Series/Fixtures.aspx') {
      return ImportTypes.Matches;
    }

    return ImportTypes.Unrecognized;
  };

  const handleSubmit = (values: { [key: string]: string }): void => {
    const importType = getImportType(values.url);

    importType === ImportTypes.Teams && props.importTeams(values.url);
    importType === ImportTypes.Matches && props.importMatches(values.url);
  };

  return (
    <Fragment>
      <Typography.Title>Import</Typography.Title>

      <Form onFinish={handleSubmit} form={form}>
        <div className={styles.form}>
          <Form.Item
            name="url"
            rules={[
              {
                type: 'url',
                message: 'Incorrect url'
              },
              { required: true, message: ' ' },
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              () => ({
                validator(rule, value): Promise<void> {
                  if (value && getImportType(value) === ImportTypes.Unrecognized) {
                    return Promise.reject('Url not recognized');
                  }

                  return Promise.resolve();
                }
              })
            ]}
            validateTrigger="onSubmit"
          >
            <Input.Search
              placeholder="Url"
              enterButton={
                <Button type="primary" htmlType="submit" id="importButton">
                  Import
                </Button>
              }
              size="large"
              onSearch={form.submit}
              loading={props.importing}
              disabled={props.importing}
            />
          </Form.Item>
        </div>

        {props.teams.length > 0 && (
          <Fragment>
            <Typography.Title level={3}>Teams</Typography.Title>

            {props.teams.map(item => (
              <Card className={styles.team} key={item.hattrickId}>
                <div className={styles.teamContent}>
                  <div className={styles.logo}>
                    <img src={item.logo} alt="logo" />
                  </div>
                  <span className={styles.name}>{item.name}</span>
                  <Button
                    type="primary"
                    data-testid="saveTeamButton"
                    onClick={(): Promise<void> => props.saveTeam(item)}
                    loading={props.savingTeams.includes(item.hattrickId)}
                  >
                    Save Team
                  </Button>
                </div>
              </Card>
            ))}
          </Fragment>
        )}

        {props.matches.length > 0 && (
          <Fragment>
            <Typography.Title level={3}>Matches</Typography.Title>

            {props.matches.map(item => (
              <Card className={styles.match} key={`${item.date}-${item.homeTeam}-${item.visitorTeam}`}>
                <div className={styles.matchContent}>
                  <span className={styles.date}>{item.date}</span>
                  <span className={styles.name}>
                    {item.homeTeam} - {item.visitorTeam}
                  </span>
                  <Button
                    type="primary"
                    data-testid="saveMatchButton"
                    onClick={(): Promise<void> => props.saveMatch(item)}
                    loading={props.savingMatches.some(
                      match =>
                        match.date === item.date &&
                        match.homeTeam === item.homeTeam &&
                        match.visitorTeam === item.visitorTeam
                    )}
                  >
                    Save Match
                  </Button>
                </div>
              </Card>
            ))}
          </Fragment>
        )}
      </Form>
    </Fragment>
  );
};

export default Import;
