import React, { useState } from 'react';
import axios from '../../helpers/axios';

import { Form, notification } from 'antd';

import ForgotPassword from './ForgotPassword';

const ForgotPasswordContainer: React.FC = () => {
  const [form] = Form.useForm();

  const [processing, setProcessing] = useState<boolean>(false);

  const sendEmail = async (email: string): Promise<void> => {
    setProcessing(true);

    try {
      await axios().post('/forgot-password', { email });

      notification.success({
        message: 'Success',
        description: 'Email with further instructions has been send',
        duration: 0
      });

      form.resetFields();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }

    setProcessing(false);
  };

  return <ForgotPassword sendEmail={sendEmail} processing={processing} form={form} />;
};

export default ForgotPasswordContainer;
