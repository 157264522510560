import { Action, combineReducers } from 'redux';

import auth from './features/auth';
import ui from './features/ui';
import seasons from './features/seasons';
import matches from './features/matches';
import teams from './features/teams';
import AppState from './interfaces/AppState';
import { signOut as signOutRoutine } from './features/auth/routines';

const appReducer = combineReducers({
  auth,
  ui,
  seasons,
  matches,
  teams
});

export default (state: AppState | undefined, action: Action): AppState => {
  let rootState = state;

  if (action.type === signOutRoutine.SUCCESS) {
    localStorage.clear();
    rootState = undefined;
  }

  return appReducer(rootState, action);
};
