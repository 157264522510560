import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Card, Row, Table } from 'antd';
import { CrownTwoTone } from '@ant-design/icons';

import { bidColor } from '../../helpers/color';

import AppState from '../../store/interfaces/AppState';
import { Match } from '../../store/features/matches/interfaces/Match';

import styles from './Dashboard.module.scss';
import { Bid } from '../../store/features/matches/interfaces/Bid';

interface Props {
  rounds: { [key: string]: Match[] };
}

const Dashboard: React.FC<Props> = (props: Props) => {
  const currentSeason = useSelector((state: AppState) => state.seasons.current);
  const leaderboard = useSelector((state: AppState) => state.matches.leaderboard);

  const iconColor = ['gold', 'silver', 'chocolate'];

  const renderColumns = (matches: Match[]): Array<{ [key: string]: string | JSX.Element }> => [
    {
      dataIndex: 'teamName',
      fixed: 'left'
    },
    ...matches.map(item => {
      return {
        title: (
          <div className={styles.header}>
            <span>{`${item.home.team} - ${item.visitor.team}`}</span>
            <span>{item.home.result !== undefined && `${item.home.result} - ${item.visitor.result}`}</span>
          </div>
        ),
        dataIndex: `match-${item._id}-result`,
        align: 'center'
      };
    })
  ];

  const renderRows = (matches: Match[]): Array<{ [key: string]: string | JSX.Element }> => {
    const rows = matches.reduce<{
      [key: string]: { [key: string]: string | JSX.Element };
    }>(
      (users, match) =>
        match.bids.reduce(
          (users, bid: Bid) => ({
            ...users,
            [bid.user._id]: {
              ...(users[bid.user._id] || {
                key: bid.user._id,
                teamName: <span className={styles.name}>{bid.user.teamName}</span>
              }),
              [`match-${match._id}-result`]: (
                <span
                  className={styles.result}
                  style={
                    typeof match.home.result === 'number' && typeof match.visitor.result === 'number'
                      ? {
                          color: 'white',
                          backgroundColor: bidColor(
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            { home: match.home.result!, visitor: match.visitor.result! },
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            { home: bid.result?.home!, visitor: bid.result?.visitor! }
                          )
                        }
                      : {
                          color: 'inherit',
                          backgroundColor: 'inherit'
                        }
                  }
                >
                  {bid.result ? bid.result?.home : '?'} - {bid.result ? bid.result?.visitor : '?'}
                </span>
              )
            }
          }),
          users
        ),
      {}
    );

    return Object.values(rows);
  };

  return (
    <Fragment>
      <Row>
        {['current', 'last'].map(roundType => (
          <div key={roundType} className={styles.round}>
            {props.rounds[roundType] && (
              <Card title={`${roundType.toUpperCase()} ROUND`}>
                <Table
                  dataSource={renderRows(props.rounds[roundType])}
                  columns={renderColumns(props.rounds[roundType])}
                  pagination={false}
                  scroll={{ x: true }}
                />
              </Card>
            )}
          </div>
        ))}
      </Row>

      <Row>
        {(leaderboard?.total || []).length > 0 && (
          <Card className={styles.leaderboard} title={`Season ${currentSeason}`}>
            {leaderboard?.total.map((item, index) => (
              <div key={item.user._id} className={styles.teamLine}>
                <span className={styles.icon}>
                  {!!iconColor[index] && <CrownTwoTone twoToneColor={iconColor[index]} />}
                </span>
                <span className={styles.team}>{item.user.teamName}</span>
                <span className={styles.points}>{item.points}</span>
                <span className={styles.smallPoints}>{item.smallPoints}</span>
              </div>
            ))}
          </Card>
        )}
      </Row>
    </Fragment>
  );
};

export default Dashboard;
