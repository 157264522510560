import axios, { AxiosStatic } from 'axios';
import { signOut } from '../store/features/auth/routines';
import store from '../store';

export default (): AxiosStatic => {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      error.response.status === 401 && setTimeout(() => store.dispatch(signOut()), 500);

      return Promise.reject(error);
    }
  );

  return axios;
};
