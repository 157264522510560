import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Button, Form, Input, Row, Col, DatePicker, InputNumber } from 'antd';

import AppState from '../../../../../store/interfaces/AppState';
import { Match } from '../../../../../store/features/matches/interfaces/Match';

import styles from './MatchForm.module.scss';

interface Props {
  processing: boolean;
  saveMatch: Function;
  match?: Partial<Match>;
}

const MatchForm: React.FC<Props> = (props: Props) => {
  const currentSeason = useSelector((state: AppState) => state.seasons.current);

  const handleSubmit = (values: { [key: string]: string }): void => {
    props.saveMatch({
      _id: props.match?._id,
      date: moment(values.date).format('YYYY-MM-DDT00:00:00'),
      home: {
        team: values.homeTeam,
        result: values.homeResult
      },
      visitor: {
        team: values.visitorTeam,
        result: values.visitorResult
      }
    });
  };

  return (
    <Form
      onFinish={handleSubmit}
      initialValues={{
        date: props.match?.date && moment(props.match?.date),
        homeTeam: props.match?.home?.team,
        homeResult: props.match?.home?.result,
        visitorTeam: props.match?.visitor?.team,
        visitorResult: props.match?.visitor?.result
      }}
    >
      <Row>
        <Col span={12}>
          <Form.Item name="date" validateTrigger="onSubmit">
            <DatePicker />
          </Form.Item>
        </Col>

        <Col span={12} className={styles.season}>
          Season: <strong>{currentSeason}</strong>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Form.Item
            name="homeTeam"
            rules={[{ required: true, message: 'Team is required' }]}
            validateTrigger="onSubmit"
          >
            <Input size="large" placeholder="Home Team" className={styles.homeTeam} />
          </Form.Item>
        </Col>

        <Col span={2}>
          <div className={styles.hypen}>-</div>
        </Col>

        <Col span={11}>
          <Form.Item
            name="visitorTeam"
            rules={[{ required: true, message: 'Team is required' }]}
            validateTrigger="onSubmit"
          >
            <Input size="large" placeholder="Visitor Team" />
          </Form.Item>
        </Col>
      </Row>

      {props.match?._id && (
        <Row>
          <Col span={5} offset={6}>
            <Form.Item name="homeResult">
              <InputNumber precision={0} min={0} max={99} className={styles.width100} />
            </Form.Item>
          </Col>

          <Col span={5} offset={2}>
            <Form.Item name="visitorResult">
              <InputNumber precision={0} min={0} max={99} className={styles.width100} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <div className={styles.actions}>
        <Button type="primary" htmlType="submit" loading={props.processing}>
          {props.match?._id ? 'Update' : 'Create'} Match
        </Button>
      </div>
    </Form>
  );
};

export default MatchForm;
