import { Action } from 'redux';

import ProfileState from '../interfaces/ProfileState';
import { ProfileSuccessAction } from '../interfaces/ProfileActions';
import { FailureAction } from '../../../interfaces/Error';
import { getProfile as getProfileRoutine } from '../routines';
import { transformError } from '../../../../helpers/error';

export const initialState: ProfileState = {
  user: null,
  processing: false,
  error: null
};

export default function reducer(state = initialState, action: Action): ProfileState {
  switch (action.type) {
    case getProfileRoutine.REQUEST:
      return {
        ...state,
        processing: true,
        error: null
      };
    case getProfileRoutine.SUCCESS:
      const user = (action as ProfileSuccessAction).payload;

      return {
        ...state,
        processing: false,
        user
      };
    case getProfileRoutine.FAILURE:
      const { code, message } = (action as FailureAction).payload || {};
      const error = transformError(message, code);

      return {
        ...state,
        processing: false,
        error
      };
    default:
      return {
        ...state
      };
  }
}
