import React, { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import axios from '../../helpers/axios';
import { useDispatch, useSelector } from 'react-redux';

import { notification, Spin } from 'antd';

import { getMatches as getMatchesRoutine } from '../../store/features/matches/routines';
import AppState from '../../store/interfaces/AppState';
import { Match } from '../../store/features/matches/interfaces/Match';
import { Bid } from '../../store/features/matches/interfaces/Bid';
import { Team } from '../../store/features/teams/interfaces/Team';

import Matches from './Matches';

const MatchesContainer: React.FC = () => {
  const dispatch = useDispatch();

  const matchesState = useSelector((state: AppState) => state.matches);
  const adminMode = useSelector((state: AppState) => state.ui.adminMode);
  const teams = useSelector((state: AppState) => state.teams.list);
  const seasons = useSelector((state: AppState) => state.seasons.list);
  const currentSeason = useSelector((state: AppState) => state.seasons.current);

  const [processing, setProcessing] = useState<boolean>(false);

  const isLastSeason = seasons[0] === currentSeason;

  const getMatches = (): void => {
    dispatch(getMatchesRoutine());
  };

  const saveMatch = async (payload: Match): Promise<void> => {
    setProcessing(true);

    const reguestBody = {
      ...payload,
      season: currentSeason
    };

    try {
      payload._id
        ? await axios().patch(`/matches/${payload._id}`, reguestBody)
        : await axios().post(`/matches`, reguestBody);

      getMatches();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }

    setProcessing(false);
  };

  const removeMatch = async (id: string): Promise<void> => {
    try {
      await axios().delete(`/matches/${id}`);

      getMatches();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }
  };

  const saveBid = async (matchID: string, payload: Bid): Promise<void> => {
    setProcessing(true);

    try {
      payload._id
        ? await axios().patch(`/matches/${matchID}/bids/${payload._id}`, payload)
        : await axios().post(`/matches/${matchID}/bids`, payload);

      getMatches();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message
      });
    }

    setProcessing(false);
  };

  useEffect(() => {
    !!matchesState.error &&
      notification.error({
        message: 'Error',
        description: matchesState.error.message
      });
  }, [matchesState.error]);

  const teamsByName = useMemo(
    () =>
      teams.reduce((acc: { [key: string]: Team }, team) => {
        acc[team.name] = team;
        return acc;
      }, {}),
    [teams]
  );

  const renderMatches = (): ReactNode => (
    <Matches
      saveMatch={saveMatch}
      removeMatch={removeMatch}
      saveBid={saveBid}
      matches={matchesState.list}
      processing={processing}
      adminMode={adminMode}
      isLastSeason={isLastSeason}
      teams={teamsByName}
    />
  );

  return matchesState.processing ? <Spin>{renderMatches()}</Spin> : <Fragment>{renderMatches()}</Fragment>;
};

export default MatchesContainer;
