import { connect } from 'react-redux';

import AppState from '../../store/interfaces/AppState';
import { signIn } from '../../store/features/auth/routines';

import Login from './Login';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: AppState) => ({
  processing: state.auth.token.processing,
  error: state.auth.token.error
});

const mapDispatchToProps = {
  signIn
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
