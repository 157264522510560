import { FC } from 'react';

import LoginPage from './index';

export default [
  {
    route: '/login',
    component: LoginPage as FC
  }
];
