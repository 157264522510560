import DashboardPage from './index';
import MainLayout from '../../components/Layout';

export default [
  {
    route: '/dashboard',
    component: DashboardPage,
    protected: true,
    layout: MainLayout
  }
];
