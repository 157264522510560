import { FC } from 'react';

import ForgotPasswordPage from './index';

export default [
  {
    route: '/forgot-password',
    component: ForgotPasswordPage as FC
  }
];
