import { FC } from 'react';

import ImportPage from './index';

export default [
  {
    route: '/import',
    component: ImportPage as FC,
    protected: true,
    admin: true
  }
];
