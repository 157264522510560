import axios from '../../../helpers/axios';
import { put, takeEvery, call } from 'redux-saga/effects';

import { getTeams } from './routines';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchTeams() {
  try {
    yield put(getTeams.request());
    const getTeamsResponse = yield call(axios().get, '/teams');

    yield put(getTeams.success(getTeamsResponse.data));
  } catch (error) {
    yield put(getTeams.failure(error.response.data));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* requestWatcherSaga() {
  yield takeEvery(getTeams.TRIGGER, fetchTeams);
}
